import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useAIMessaging from '../../features/messaging/useAIMessaging.hooks';
import {
  ICancelOrderTransmissionMessage,
  IHITLSessionEndTransmissionMessage,
  IHITLSessionStartTransmissionMessage,
  resetHypothesisFrame,
  sendCancelOrder,
  sendHITLSessionEnd,
  sendHITLSessionStart,
} from '../../reducers/messagingSlice';
import { orderActions } from '../../reducers/orderSlice';
import { getAIControlPanelStatus } from '../../reducers/restaurantSlice';
import { resetAI, toggleReasonsDialog } from '../../redux/features/ai/ai.slice';
import {
  SessionStartReasons,
  SessionStatus,
} from '../../redux/features/sessionBoundary/sessionBoundary.constants';
import {
  selectOrderId,
  selectSessionEndReason,
  selectSessionStartReason,
  selectSessionStatus,
  selectEndedSessionId,
} from '../../redux/features/sessionBoundary/sessionBoundary.selectors';
import { setSessionStart } from '../../redux/features/sessionBoundary/sessionBoundary.slice';
import { selectCurrentTask } from '../../redux/features/taskRouter/taskRouter.selector';
import { selectActiveRestaurantCode } from '../../selectors/restaurant';
import logger from '../../utils/logger';
import useTaskRouterHandlers from '../taskRouterHandlers/taskRouterHandlers.hooks';
import { useSelector } from 'react-redux';
import { selectLastHITLOrderId } from '../../selectors/order';
import { EventSources } from '../../constants/event';
import {
  selectCurrentSessionId,
  selectIsNewSession,
} from '../../selectors/message';

const useSessionStatus = () => {
  const dispatch = useAppDispatch();
  const sessionStatus = useAppSelector(selectSessionStatus);
  const sessionStartReason = useAppSelector(selectSessionStartReason);
  const sessionEndReason = useAppSelector(selectSessionEndReason);
  const currentTaskId = useAppSelector(selectCurrentTask);
  const { endTask } = useTaskRouterHandlers();
  const restaurantCode = useAppSelector(selectActiveRestaurantCode);
  const { isAIActive, isAIAutoMode } = useAIMessaging();
  const aiOrderId = useSelector(selectOrderId);
  const lastHITLOrderId = useSelector(selectLastHITLOrderId);
  const lastOrderId = aiOrderId || lastHITLOrderId;
  const wsCurrentSessionId = useAppSelector(selectCurrentSessionId);
  const sbEndedSessionId = useAppSelector(selectEndedSessionId);
  const isWSCurrentSessionIdNewSession = useAppSelector(selectIsNewSession);

  useEffect(() => {
    if (sessionStatus === SessionStatus.end) {
      const payload: Partial<IHITLSessionEndTransmissionMessage> = {
        data: { reason: sessionEndReason || '' },
      };
      dispatch(sendHITLSessionEnd(payload as any));
      if (lastOrderId) {
        logger.log(
          `Send cancel_order for order_id ${lastOrderId} when session boundary ends.`
        );
        const payload: Partial<ICancelOrderTransmissionMessage> = {
          data: {
            check_id: '-1',
            source: EventSources.prestoVoice,
            request_id: uuidv4(),
            store_id: restaurantCode,
            order_id: lastOrderId,
          },
        };
        dispatch(sendCancelOrder(payload as any));
      }
      if (isAIActive && !isAIAutoMode) {
        dispatch(toggleReasonsDialog(true));
      } else if (
        !currentTaskId &&
        wsCurrentSessionId &&
        sbEndedSessionId &&
        wsCurrentSessionId !== sbEndedSessionId &&
        isWSCurrentSessionIdNewSession
      ) {
        // A car enter before the current session ends so starting the new session right after the current session ends for non TR session
        logger.log(
          'Set HITL session start reason as back_to_back when session id in websocket is different from the session session boundary end'
        );
        dispatch(
          setSessionStart({
            sessionId: wsCurrentSessionId,
            sessionStartReason: SessionStartReasons.backToBack,
          })
        );
      } else {
        logger.log('Call endTask when HITL session ends in useSessionStatus');
        endTask(); // End task when a session ends
      }
    } else if (sessionStatus === SessionStatus.start) {
      const payload: Partial<IHITLSessionStartTransmissionMessage> = {
        data: { reason: sessionStartReason || '' },
      };
      dispatch(sendHITLSessionStart(payload as any));
      if (restaurantCode && !currentTaskId) {
        dispatch(resetHypothesisFrame());
        dispatch(resetAI());
        // Get updated AI on/off status when a new HITL session starts, but don't execute when the task is received via task-router
        dispatch(getAIControlPanelStatus({ restaurantCode }));
      }
      logger.log(
        'Reset currentSession in order slice when HITL session starts in useSessionStatus'
      );
      dispatch(orderActions.setCurrentSession('')); //Resetting the hitlSessionID in orderSlice whenever new session starts
    }
  }, [sessionStatus]);
};

export default useSessionStatus;
