import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import useTaskRouterHandlers from '../../hooks/taskRouterHandlers/taskRouterHandlers.hooks';
import {
  InfoTransmissionMessage,
  messagingActions,
} from '../../reducers/messagingSlice';
import { toggleReasonsDialog } from '../../redux/features/ai/ai.slice';
import {
  selectInterceptionOtherReasonTimer,
  selectInterceptionReasonsTimer,
} from '../../redux/features/config/config.selector';
import { SessionStartReasons } from '../../redux/features/sessionBoundary/sessionBoundary.constants';
import { setSessionStart } from '../../redux/features/sessionBoundary/sessionBoundary.slice';
import Colors from '../../utils/color';
import logger from '../../utils/logger';
import DwindlingTimer from '../DwindlingTimer';
import {
  selectCurrentSessionId,
  selectIsNewSession,
} from '../../selectors/message';
import { selectEndedSessionId } from '../../redux/features/sessionBoundary/sessionBoundary.selectors';

const OTHER_REASON = 'Other Reason';
const TIMEOUT_REASON = 'agent did not input reason';
const reasons = [
  'Incorrect Cart Item',
  'AI Delayed Response',
  'AI Incorrect Response',
  'Audio Quality',
  'AI Interruptions',
  'Customer Response Time',
  OTHER_REASON,
];

const useStyles = makeStyles((theme: Theme) => ({
  titleArea: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(8),
    justifyContent: 'space-between',
  },
  actionArea: {
    borderTop: `${theme.spacing(0.125)} solid ${Colors.alto}`,
  },
  contentArea: {
    borderTop: `${theme.spacing(0.125)} solid ${Colors.alto}`,
    padding: `${theme.spacing(3)} !important`,
    '& p': {
      paddingBottom: theme.spacing(1),
      color: Colors.black,
    },
  },
  buttonStyle: {
    textTransform: 'none',
    fontSize: theme.spacing(2),
  },
}));

export default function InterceptionReasonsDialog() {
  const { titleArea, actionArea, contentArea, buttonStyle } = useStyles({});
  const [selectedReason, updateSelectedReason] = useState('');
  const [otherReasonMessage, updateOtherReasonMessage] = useState('');
  const maxTimerSeconds = useAppSelector(selectInterceptionReasonsTimer);
  const maxOtherReasonTimerSeconds = useAppSelector(
    selectInterceptionOtherReasonTimer
  );
  const dispatch = useDispatch();
  const { isCurrentTaskFromTaskRouter, navigateToTaskRouterPage } =
    useTaskRouterHandlers();
  const wsCurrentSessionId = useAppSelector(selectCurrentSessionId);
  const sbEndedSessionId = useAppSelector(selectEndedSessionId);
  const isWSCurrentSessionIdNewSession = useAppSelector(selectIsNewSession);

  const sendInterceptionReason = ({
    passedReason,
    passedReasonMessage,
  }: {
    passedReason?: string;
    passedReasonMessage?: string;
  }) => {
    const reason = passedReason || OTHER_REASON;
    const details = passedReasonMessage || otherReasonMessage;
    const message = `Interception reason: ${reason} is ${
      details === TIMEOUT_REASON ? 'auto ' : ''
    }selected`;
    const payload: Partial<InfoTransmissionMessage> = {
      data: {
        message,
        type: 'METRIC',
        metadata: {
          event_name: 'interception_reason',
          reason,
          ...(details && { details }),
        },
      },
    };
    dispatch(messagingActions.sendInfo(payload as any));
    dispatch(toggleReasonsDialog(false));
    if (isCurrentTaskFromTaskRouter) {
      logger.log(
        'Call navigateToTaskRouterPage after sending interception reason in InterceptionReasonsDialog'
      );
      navigateToTaskRouterPage();
    }

    if (
      !isCurrentTaskFromTaskRouter &&
      wsCurrentSessionId &&
      sbEndedSessionId &&
      wsCurrentSessionId !== sbEndedSessionId &&
      isWSCurrentSessionIdNewSession
    ) {
      // A car enter before the current session ends so starting the new session right after the current session ends for non TR session
      logger.log(
        'Set HITL session start reason as back_to_back when session id in websocket is different from the session session boundary end in InterceptionReasonsDialog for non TR session'
      );
      dispatch(
        setSessionStart({
          sessionId: wsCurrentSessionId,
          sessionStartReason: SessionStartReasons.backToBack,
        })
      );
    }
  };

  const handleSelectReason = (reason: string) => {
    updateSelectedReason(reason);
    if (reason !== OTHER_REASON)
      sendInterceptionReason({ passedReason: reason });
  };

  const handleOtherReasonChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    updateOtherReasonMessage(e.target.value);
  };

  return (
    <Dialog
      open={true}
      PaperProps={{ sx: { maxWidth: '700px', width: '700px' } }}
    >
      <DialogTitle className={titleArea}>
        <div>What was the reason you intercepted on AI order?</div>
        <DwindlingTimer
          key={selectedReason}
          maxTime={
            selectedReason === OTHER_REASON
              ? maxOtherReasonTimerSeconds
              : maxTimerSeconds
          }
          timeoutCallback={() =>
            sendInterceptionReason({ passedReasonMessage: TIMEOUT_REASON })
          }
        />
      </DialogTitle>
      <DialogContent className={contentArea}>
        {selectedReason === OTHER_REASON ? (
          <>
            <DialogContentText>
              Please specify with more details (Required)
            </DialogContentText>
            <TextField
              data-testid="interventionReason"
              label="Specify the reason (Required)"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              rows={2}
              helperText="Max 200 characters"
              inputProps={{ maxLength: 200 }}
              autoFocus
              onChange={handleOtherReasonChange}
              value={otherReasonMessage}
            />
          </>
        ) : (
          <>
            {reasons.map((reason) => (
              <Button
                key={reason}
                data-testid={`reason-${reason}`}
                variant="outlined"
                size="large"
                fullWidth
                sx={{ mt: 1.5 }}
                onClick={() => handleSelectReason(reason)}
                className={buttonStyle}
              >
                {reason}
              </Button>
            ))}
          </>
        )}
      </DialogContent>
      {selectedReason === OTHER_REASON && (
        <DialogActions className={actionArea}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!otherReasonMessage.length}
            onClick={() => sendInterceptionReason({})}
          >
            Submit
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
